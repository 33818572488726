<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) You are given a 3.00&nbsp;M stock solution of
        <stemble-latex content="$\ce{HCl}\text{.}$" />
        You need to make
        <stemble-latex content="$25.00\,\text{mL}$" />
        of a
        <number-value :value="conc" unit="\text{M}" />
        solution of
        <stemble-latex content="$\ce{HCl}\text{.}$" />
        What volume of the stock solution (in mL) will you need to use?
      </p>

      <calculation-input
        v-model="inputs.input1"
        prepend-text="$\text{V}:$"
        append-text="$\text{mL}$"
        :disabled="!allowEditing"
        class="mb-5"
      />

      <p class="mb-2">b) Explain how you came to your answer in part a).</p>

      <p class="mb-n6">
        <s-textarea v-model="inputs.explainedResponse" outlined :counter="true" :maxlength="1200" />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';

export default {
  name: 'ChemFSU101DemoQ1',
  components: {
    STextarea,
    StembleLatex,
    NumberValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        explainedResponse: null,
      },
    };
  },
  computed: {
    conc() {
      return this.taskState.getValueBySymbol('conc').content;
    },
  },
};
</script>
